.hor_spacer.mainPage {
    pointer-events: all;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    font: bold 15px Verdana;
}

.homeSynopsis {
    color: white;
    width: 90%;
    margin: auto;
    overflow-y: visible;
    font: bold 15px Verdana;
}

.homeSynopsis > p:last-of-type {
    font-size: x-large;
    font-weight: 700;
}

.homeSynopsis > h1 {
    font-size: 4em;
    font-weight: 900;
    color: yellow;
    font-family: "Madimi One", sans-serif;
}

.homeSynopsis::-webkit-scrollbar {
    width: 0;
}

.points {
    color: yellow;
    background-color: gray;
    border: 5px solid yellow;
    border-radius: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    font: bold 15px Verdana;
}

.points > ul {
    width: fit-content;
    font-weight: 700;

}

.formLabel > div > div > label {
    color: white !important;
}

.gameSubForm {
    width: 50%;
    height: 98%;
    color: yellow;
    border: 5px solid gray;
    border-radius: 10px;
    overflow-y: auto;;
}

.gameSubForm::-webkit-scrollbar {
    width: 0;
}

.currentGame {
    width: 50%;
    height: 100%;
    color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.currentGameImage {
    width: 25%;
}

.currentGameText {
    width: 50%;
}

.currentGameText > h2 {
    font-size: 2vw;
    font-weight: 700;
}